.ant-table-thead th {
  background: @primary-color !important;
  color: white !important;
  .ant-table-filter-trigger-container {
    &:hover,
    .ant-table-filter-trigger.active,
    &-open {
      background-color: @primary-color !important;
    }
    .ant-table-filter-trigger.active span {
      color: white !important;
    }
  }
}

.striped-table {
  tr:nth-child(2n) td {
    background-color: #f7f5f9;
  }
}

.ant-btn-success {
  color: white !important;
  background-color: #C51B7A !important;
  border-color: #C51B7A !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: white !important;
  background-color: #e4218e !important;
  border-color: #e4218e !important;
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: white !important;
  background-color: #a51766 !important;
  border-color: #a51766 !important;
}
.ant-btn-success.ant-btn-background-ghost {
  color: #C51B7A !important;
  background: transparent !important;
  border-color: #C51B7A !important;
  text-shadow: none !important;
}
.ant-btn-success.ant-btn-background-ghost:hover,
.ant-btn-success.ant-btn-background-ghost:focus {
  color: #e4218e !important;
  background: transparent !important;
  border-color: #e4218e !important;
}
.ant-btn-success.ant-btn-background-ghost:active,
.ant-btn-success.ant-btn-background-ghost.active {
  color: #C51B7A !important;
  background: transparent !important;
  border-color: #a51766 !important;
}
.ant-btn-light {
  color: #606060 !important;
  background-color: #F7F5F9 !important;
  border-color: #F7F5F9 !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-light-disabled,
.ant-btn-light.disabled,
.ant-btn-light[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-light-disabled:hover,
.ant-btn-light.disabled:hover,
.ant-btn-light[disabled]:hover,
.ant-btn-light-disabled:focus,
.ant-btn-light.disabled:focus,
.ant-btn-light[disabled]:focus,
.ant-btn-light-disabled:active,
.ant-btn-light.disabled:active,
.ant-btn-light[disabled]:active,
.ant-btn-light-disabled.active,
.ant-btn-light.disabled.active,
.ant-btn-light[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-light:hover,
.ant-btn-light:focus {
  color: #606060 !important;
  background-color: #EDECEE !important;
  border-color: #EDECEE !important;
}
.ant-btn-light:active,
.ant-btn-light.active {
  color: #606060 !important;
  background-color: #F2F0F3 !important;
  border-color: #F2F0F3 !important;
}
.ant-btn-light.ant-btn-background-ghost {
  color: #F7F5F9 !important;
  background: transparent !important;
  border-color: #F7F5F9 !important;
  text-shadow: none !important;
}
.ant-btn-light.ant-btn-background-ghost:hover,
.ant-btn-light.ant-btn-background-ghost:focus {
  color: #EDECEE !important;
  background: transparent !important;
  border-color: #EDECEE !important;
}
.ant-btn-light.ant-btn-background-ghost:active,
.ant-btn-light.ant-btn-background-ghost.active {
  color: #F7F5F9 !important;
  background: transparent !important;
  border-color: #F2F0F3 !important;
}
.ant-btn-action_edit {
  color: black !important;
  background-color: #D1C0DB !important;
  border-color: #D1C0DB !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-action_edit-disabled,
.ant-btn-action_edit.disabled,
.ant-btn-action_edit[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_edit-disabled:hover,
.ant-btn-action_edit.disabled:hover,
.ant-btn-action_edit[disabled]:hover,
.ant-btn-action_edit-disabled:focus,
.ant-btn-action_edit.disabled:focus,
.ant-btn-action_edit[disabled]:focus,
.ant-btn-action_edit-disabled:active,
.ant-btn-action_edit.disabled:active,
.ant-btn-action_edit[disabled]:active,
.ant-btn-action_edit-disabled.active,
.ant-btn-action_edit.disabled.active,
.ant-btn-action_edit[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_edit:hover,
.ant-btn-action_edit:focus {
  color: black !important;
  background-color: #E7D5F2 !important;
  border-color: #E7D5F2 !important;
}
.ant-btn-action_edit:active,
.ant-btn-action_edit.active {
  color: black !important;
  background-color: #BDADC7 !important;
  border-color: #BDADC7 !important;
}
.ant-btn-action_edit.ant-btn-background-ghost {
  color: #D1C0DB !important;
  background: transparent !important;
  border-color: #D1C0DB !important;
  text-shadow: none !important;
}
.ant-btn-action_edit.ant-btn-background-ghost:hover,
.ant-btn-action_edit.ant-btn-background-ghost:focus {
  color: #E7D5F2 !important;
  background: transparent !important;
  border-color: #E7D5F2 !important;
}
.ant-btn-action_edit.ant-btn-background-ghost:active,
.ant-btn-action_edit.ant-btn-background-ghost.active {
  color: #D1C0DB !important;
  background: transparent !important;
  border-color: #BDADC7 !important;
}
.ant-btn-action_delete {
  color: black !important;
  background-color: #D7D4E0 !important;
  border-color: #D7D4E0 !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-action_delete-disabled,
.ant-btn-action_delete.disabled,
.ant-btn-action_delete[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_delete-disabled:hover,
.ant-btn-action_delete.disabled:hover,
.ant-btn-action_delete[disabled]:hover,
.ant-btn-action_delete-disabled:focus,
.ant-btn-action_delete.disabled:focus,
.ant-btn-action_delete[disabled]:focus,
.ant-btn-action_delete-disabled:active,
.ant-btn-action_delete.disabled:active,
.ant-btn-action_delete[disabled]:active,
.ant-btn-action_delete-disabled.active,
.ant-btn-action_delete.disabled.active,
.ant-btn-action_delete[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_delete:hover,
.ant-btn-action_delete:focus {
  color: black !important;
  background-color: #ECE9F5 !important;
  border-color: #ECE9F5 !important;
}
.ant-btn-action_delete:active,
.ant-btn-action_delete.active {
  color: black !important;
  background-color: #D0CED8 !important;
  border-color: #D0CED8 !important;
}
.ant-btn-action_delete.ant-btn-background-ghost {
  color: #D7D4E0 !important;
  background: transparent !important;
  border-color: #D7D4E0 !important;
  text-shadow: none !important;
}
.ant-btn-action_delete.ant-btn-background-ghost:hover,
.ant-btn-action_delete.ant-btn-background-ghost:focus {
  color: #ECE9F5 !important;
  background: transparent !important;
  border-color: #ECE9F5 !important;
}
.ant-btn-action_delete.ant-btn-background-ghost:active,
.ant-btn-action_delete.ant-btn-background-ghost.active {
  color: #D7D4E0 !important;
  background: transparent !important;
  border-color: #D0CED8 !important;
}
.ant-btn-action {
  color: black !important;
  background-color: #BEEBBA !important;
  border-color: #BEEBBA !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-action-disabled,
.ant-btn-action.disabled,
.ant-btn-action[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action-disabled:hover,
.ant-btn-action.disabled:hover,
.ant-btn-action[disabled]:hover,
.ant-btn-action-disabled:focus,
.ant-btn-action.disabled:focus,
.ant-btn-action[disabled]:focus,
.ant-btn-action-disabled:active,
.ant-btn-action.disabled:active,
.ant-btn-action[disabled]:active,
.ant-btn-action-disabled.active,
.ant-btn-action.disabled.active,
.ant-btn-action[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action:hover,
.ant-btn-action:focus {
  color: black !important;
  background-color: #CFFFCB !important;
  border-color: #CFFFCB !important;
}
.ant-btn-action:active,
.ant-btn-action.active {
  color: black !important;
  background-color: #B6E0B2 !important;
  border-color: #B6E0B2 !important;
}
.ant-btn-action.ant-btn-background-ghost {
  color: #BEEBBA !important;
  background: transparent !important;
  border-color: #BEEBBA !important;
  text-shadow: none !important;
}
.ant-btn-action.ant-btn-background-ghost:hover,
.ant-btn-action.ant-btn-background-ghost:focus {
  color: #CFFFCB !important;
  background: transparent !important;
  border-color: #CFFFCB !important;
}
.ant-btn-action.ant-btn-background-ghost:active,
.ant-btn-action.ant-btn-background-ghost.active {
  color: #BEEBBA !important;
  background: transparent !important;
  border-color: #B6E0B2 !important;
}
.ant-btn-action_pause {
  color: black !important;
  background-color: #FAE3B8 !important;
  border-color: #FAE3B8 !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-action_pause-disabled,
.ant-btn-action_pause.disabled,
.ant-btn-action_pause[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_pause-disabled:hover,
.ant-btn-action_pause.disabled:hover,
.ant-btn-action_pause[disabled]:hover,
.ant-btn-action_pause-disabled:focus,
.ant-btn-action_pause.disabled:focus,
.ant-btn-action_pause[disabled]:focus,
.ant-btn-action_pause-disabled:active,
.ant-btn-action_pause.disabled:active,
.ant-btn-action_pause[disabled]:active,
.ant-btn-action_pause-disabled.active,
.ant-btn-action_pause.disabled.active,
.ant-btn-action_pause[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_pause:hover,
.ant-btn-action_pause:focus {
  color: black !important;
  background-color: #FFF0D4 !important;
  border-color: #FFF0D4 !important;
}
.ant-btn-action_pause:active,
.ant-btn-action_pause.active {
  color: black !important;
  background-color: #EDD8B0 !important;
  border-color: #EDD8B0 !important;
}
.ant-btn-action_pause.ant-btn-background-ghost {
  color: #FAE3B8 !important;
  background: transparent !important;
  border-color: #FAE3B8 !important;
  text-shadow: none !important;
}
.ant-btn-action_pause.ant-btn-background-ghost:hover,
.ant-btn-action_pause.ant-btn-background-ghost:focus {
  color: #FFF0D4 !important;
  background: transparent !important;
  border-color: #FFF0D4 !important;
}
.ant-btn-action_pause.ant-btn-background-ghost:active,
.ant-btn-action_pause.ant-btn-background-ghost.active {
  color: #FAE3B8 !important;
  background: transparent !important;
  border-color: #EDD8B0 !important;
}
.ant-btn-action_stop {
  color: black !important;
  background-color: #EBC3BA !important;
  border-color: #EBC3BA !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-action_stop-disabled,
.ant-btn-action_stop.disabled,
.ant-btn-action_stop[disabled] {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_stop-disabled:hover,
.ant-btn-action_stop.disabled:hover,
.ant-btn-action_stop[disabled]:hover,
.ant-btn-action_stop-disabled:focus,
.ant-btn-action_stop.disabled:focus,
.ant-btn-action_stop[disabled]:focus,
.ant-btn-action_stop-disabled:active,
.ant-btn-action_stop.disabled:active,
.ant-btn-action_stop[disabled]:active,
.ant-btn-action_stop-disabled.active,
.ant-btn-action_stop.disabled.active,
.ant-btn-action_stop[disabled].active {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(27, 179, 141, 0.5) !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-action_stop:hover,
.ant-btn-action_stop:focus {
  color: black !important;
  background-color: #FFD4CB !important;
  border-color: #FFD4CB !important;
}
.ant-btn-action_stop:active,
.ant-btn-action_stop.active {
  color: black !important;
  background-color: #DBB5AC !important;
  border-color: #DBB5AC !important;
}
.ant-btn-action_stop.ant-btn-background-ghost {
  color: #EBC3BA !important;
  background: transparent !important;
  border-color: #EBC3BA !important;
  text-shadow: none !important;
}
.ant-btn-action_stop.ant-btn-background-ghost:hover,
.ant-btn-action_stop.ant-btn-background-ghost:focus {
  color: #FFD4CB !important;
  background: transparent !important;
  border-color: #FFD4CB !important;
}
.ant-btn-action_stop.ant-btn-background-ghost:active,
.ant-btn-action_stop.ant-btn-background-ghost.active {
  color: #EBC3BA !important;
  background: transparent !important;
  border-color: #DBB5AC !important;
}
.ant-table-thead th {
  background: #4F226D !important;
  color: white !important;
}
.ant-table-thead th .ant-table-filter-trigger-container:hover,
.ant-table-thead th .ant-table-filter-trigger-container .ant-table-filter-trigger.active,
.ant-table-thead th .ant-table-filter-trigger-container-open {
  background-color: #4F226D !important;
}
.ant-table-thead th .ant-table-filter-trigger-container .ant-table-filter-trigger.active span {
  color: white !important;
}
.striped-table tr:nth-child(2n) td {
  background-color: #f7f5f9;
}
.ant-modal-mask {
  background-color: rgba(25, 10, 34, 0.76) !important;
}
#root {
  height: 100%;
}
.main__wrapper {
  min-height: 550px;
}
.ant-layout {
  background-color: #fff !important;
}
.create-container .ant-input-number {
  width: 100% !important;
}
.create-container .info {
  font-size: 12px !important;
}
.create-container .buttons {
  margin-top: 12px;
}
.create-container .buttons > button {
  float: right;
}
.create-container .ant-picker {
  width: 100%;
}
.create-container .ant-radio-wrapper {
  display: block !important;
}
.action {
  float: right;
  padding: 0px;
  line-height: 15px;
  height: 22px;
}
.ant-table .actions button {
  margin-left: 5px !important;
}
.alert-icon {
  color: #C51B7A !important;
}
.popover-details:last-of-type {
  margin-bottom: 0 !important;
}

@color-disabled: rgba(27, 179, 141, 0.5);

.disabled() {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: @color-disabled !important;
  border-color: white !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.new-button(@name, @text-color, @color, @color-focus, @color-active) {
  .ant-btn-@{name}{
    color: @text-color !important;
    background-color: @color !important;
    border-color: @color !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &-disabled,
    &.disabled,
    &[disabled] {
      .disabled();
      &:hover,
      &:focus,
      &:active,
      &.active {
        .disabled();
      }
    }
    &:hover,
    &:focus {
      color: @text-color !important;
      background-color: @color-focus !important;
      border-color: @color-focus !important;
    }
    &:active,
    &.active {
      color: @text-color !important;
      background-color: @color-active !important;
      border-color: @color-active !important;
    }
    &.ant-btn-background-ghost {
      color: @color !important;
      background: transparent !important;
      border-color: @color !important;
      text-shadow: none !important;
      &:hover,
      &:focus {
        color: @color-focus !important;
        background: transparent !important;
        border-color: @color-focus !important;
      }
      &:active,
      &.active {
        color: @color !important;
        background: transparent !important;
        border-color: @color-active !important;
      }
    }
  }
}

@success: #C51B7A;
@success-focus: #e4218e;
@success-active: #a51766;
// @success: #1bb38d;
// @success-focus: #21d8aa;
// @success-active: #1ab58e;
.new-button(success, white, @success, @success-focus, @success-active);

@light: #F7F5F9;
@light-focus: #EDECEE;
@light-active: #F2F0F3;
.new-button(light, #606060, @light, @light-focus, @light-active);

@action-edit: #D1C0DB;
@action-edit-focus: #E7D5F2;
@action-edit-active: #BDADC7;
.new-button(action_edit, black, @action-edit, @action-edit-focus, @action-edit-active);

@action-delete: #D7D4E0;
@action-delete-focus: #ECE9F5;
@action-delete-active: #D0CED8;
.new-button(action_delete, black, @action-delete, @action-delete-focus, @action-delete-active);

@action: #BEEBBA;
@action-focus: #CFFFCB;
@action-active: #B6E0B2;
.new-button(action, black, @action, @action-focus, @action-active);

@action-pause: #FAE3B8;
@action-pause-focus: #FFF0D4;
@action-pause-active: #EDD8B0;
.new-button(action_pause, black, @action-pause, @action-pause-focus, @action-pause-active);

@action-stop: #EBC3BA;
@action-stop-focus: #FFD4CB;
@action-stop-active: #DBB5AC;
.new-button(action_stop, black, @action-stop, @action-stop-focus, @action-stop-active);

